import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
// import { Snowfall } from "react-snowfall";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <div
    style={{
      position: "relative",
    }}
  >
    <App />
    {/* <Snowfall snowflakeCount={400} /> */}
  </div>
);
